import initializeMenus, { toggleMenu } from "./menus";
import Metronome from "./Metronome";
import Mixer from "./Mixer";
import tapTempo from "./tapTempo";
import {
  btnStartStop,
  btnTap,
  tempoSlider,
  volumeSlider,
  loadingContainer,
  mainContainer,
  potEvents,
  tempoNumber,
  tempoPot,
} from "./ui";
import Visualizer from "./Visualizer";

declare const plausible: any;
declare const process: any;

export const __DEV__ = process?.env?.NODE_ENV === "development";

let hasChangedTempo = false;
let hasStartedMetronome = false;
let hasMarkedActive = false;

function onActivity(activity: "set-tempo" | "start-metronome") {
  if (hasMarkedActive) {
    // we are done here
    return;
  }

  if (activity === "set-tempo") {
    hasChangedTempo = true;
  } else if (activity === "start-metronome") {
    hasStartedMetronome = true;
  }

  if (hasChangedTempo && hasStartedMetronome) {
    hasMarkedActive = true;
    if (__DEV__) {
      console.log("ignoring plausible in dev");
    } else {
      plausible("has-been-active");
    }
  }
}

const mixer = new Mixer();
const metronome = new Metronome(
  mixer.context,
  (when) => mixer.playSound(when),
  (tickTock) => visualizer.onClick(tickTock)
);
const visualizer = new Visualizer(metronome);

const documentLoaded = () =>
  new Promise((resolve, reject) => {
    window.addEventListener("DOMContentLoaded", (event) => {
      resolve("test");
    });
  });

const preload = () => {
  Promise.all([
    documentLoaded(),
    visualizer.preload(),
    mixer.preloadAudio(),
  ]).then((values) => {
    loadingContainer.classList.add("hidden");
    loadingContainer.setAttribute("aria-hidden", "true");
    mainContainer.setAttribute("aria-hidden", "false");
    initializeMenus();
  });
};

preload();

// UI
btnStartStop.addEventListener("click", (event) => {
  if (metronome.nextClickTime) {
    stopMetronome();
  } else {
    startMetronome();
  }
});

// TODO: Move into potEvents function
tempoSlider.addEventListener("input", (event) =>
  setTempo(Number((<HTMLInputElement>event.target).value))
);

potEvents(tempoSlider, tempoPot, (delta) => {
  const sensitivity =
    metronome.tempo < 50
      ? 0.12
      : metronome.tempo < 73
      ? 0.17
      : metronome.tempo < 120
      ? 0.2
      : metronome.tempo < 144
      ? 0.25
      : 0.3;

  setTempo(metronome.tempo + delta * sensitivity);
});

btnTap.addEventListener("click", (event) => {
  let tempo = tapTempo();
  if (tempo) {
    setTempo(Math.round(tempo));
  }
});

// Keyboard shortcuts
window.addEventListener("keydown", (event) => {
  const tempoDelta = event.shiftKey ? 10 : 2;

  switch (event.code) {
    case "KeyT":
      let tempo = tapTempo();
      if (tempo) {
        setTempo(Math.round(tempo));
      }
      break;

    case "KeyS":
      if (metronome.nextClickTime) {
        stopMetronome();
      } else {
        startMetronome();
      }
      break;

    case "KeyA":
      setTempo(metronome.tempo + tempoDelta);
      break;
    case "KeyZ":
      setTempo(metronome.tempo - tempoDelta);
      break;

    case "Esc":
    case "Escape":
      toggleMenu();
      break;
  }
});

// State
const setTempo = (tempo: number, source?: "init") => {
  tempo = Math.min(tempo, metronome.MAX_TEMPO);
  tempo = Math.max(tempo, metronome.MIN_TEMPO);

  tempoSlider.setAttribute("min", metronome.MIN_TEMPO);
  tempoSlider.setAttribute("max", metronome.MAX_TEMPO);

  metronome.setTempo(tempo);
  if (tempoSlider) {
    tempoSlider.value = tempo.toString();
  }
  tempoNumber.innerText = "" + Math.round(tempo);

  const minDegrees = -150;
  const maxDegrees = 150;
  const tempoRange = metronome.MAX_TEMPO - metronome.MIN_TEMPO;
  const degreeRange = maxDegrees - minDegrees;
  const percent = (tempo - metronome.MIN_TEMPO) / tempoRange;
  const degrees = minDegrees + degreeRange * percent;
  tempoPot.style.transform = `rotate(${degrees}deg)`;

  if (source !== "init") {
    onActivity("set-tempo");
  }
};

const setVol = (vol: number) => {
  mixer.setVolume(vol);
  if (volumeSlider) {
    volumeSlider.value = vol.toString();
  }
};

const startMetronome = () => {
  metronome.start();
  mixer.resume();
  btnStartStop.setAttribute("aria-checked", "true");
  onActivity("start-metronome");
};

const stopMetronome = () => {
  metronome.stop();
  btnStartStop.setAttribute("aria-checked", "false");
};

// Initialize defaults
setTempo(90, "init");
setVol(0.8);
