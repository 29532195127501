const impulseResponse = new URL("./assets/click.m4a", import.meta.url);

const Mixer = function () {
  // @ts-ignore
  const AudioContext = window.AudioContext || window.webkitAudioContext;
  this.context = new AudioContext();
  this.gainNode = this.context.createGain();
  this.gainNode.gain.setValueAtTime(0.9, this.context.currentTime);
  this.gainNode.connect(this.context.destination);
};

Mixer.prototype.preloadAudio = async function () {
  const decodeAudioBuffer = (arrayBuffer: ArrayBuffer) => {
    return new Promise((resolve, reject) => {
      this.context.decodeAudioData(
        arrayBuffer,
        (audio: AudioBuffer) => resolve(audio),
        (error) => reject(error)
      );
    });
  };
  try {
    const response = await fetch(impulseResponse);
    const arrayBuffer = await response.arrayBuffer();
    this.audioBuffer = await decodeAudioBuffer(arrayBuffer);
  } catch (err) {
    console.error("Could not load audio!", { err });
  }
};

Mixer.prototype.playSound = function (startTime) {
  if (this.audioBuffer) {
    const sampleSource = this.context.createBufferSource();
    sampleSource.buffer = this.audioBuffer;
    sampleSource.connect(this.gainNode);
    sampleSource.start(startTime);
  }
};

Mixer.prototype.setVolume = function (vol) {
  this.gainNode.gain.setValueAtTime(vol, this.context.currentTime);
};

Mixer.prototype.resume = function () {
  if (this.context.state === "suspended") {
    this.context.resume();
  }
};

export default Mixer;
